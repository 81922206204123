/**
 * 
 */

import React, { forwardRef, ReactNode, Ref, useEffect, useImperativeHandle } from "react";
import { IJsonSchemaObject, IUiSchema, IUiSchemaObject, IUiSchemaLayoutOptions, IUiSchemaPanelLayoutOptions, IUiSchemaDropFile, IUiSchemaCardLayoutOptions, IResourceResponseObject, IUiActionButton, CardListElem, UiSchemaTrust } from "../UiJsonSchemaTypes";
import { ISchemaOptions, evalString, evalExpr, getObjectValues, ISchemaLib, proxyClone, updateConditionalSchema, evalSchemaElem, IInnerStates, IExprObjects, getRef, IControl } from "./SchemaTools";
import { IComponentHandlers, ITextMarkerHandlers, registerComponentHandler, registeredExtensionFormsComponents, registeredExtensionHandlers, registeredExtensionLib, registerExtensionLibFunction } from "./SchemaExtensions";
import { schemaElemColLayout } from "./SchemaElemColLayout";
import { embedObject } from "./SchemaEmbedObject";
import { ArrayRow, embedArrayContainer, embedArrayElement, embedArrayElementObject, renderArrayTable } from "./SchemaArrayElems";
import { addTabElem, formatTextArgs, getControlButtons, highlightUnlock, render, updateLayout } from "./SchemaPanel";
import { ISchemaLayoutComponentRef } from "./SchemaLayout";

import "./SchemaCoreComponents";
import "./SchemaLayout";		// default render
import { handleNewSchemaResources, handleResource } from "./SchemaResources";


// TODO:
// - add transformers. More convenient way to transform on load and apply. For example for map <-> array etc.
// - apply defaults after load
// - track changes
// - change and error per card and panel



// Controllers


export interface IUiSchemaUpdateState {
	currentValues?: any;
	lastValues?: any;

	objectErrors?: any;
	oldValues?: any;
	activeTab?: string;
	close?: boolean;
	success?: boolean;
	readOnly?: boolean;
	debug?: boolean;
	ready?: boolean;

	update?: boolean;
	jsonSchema?: any;
}

export interface IUiSchemaSetValueResult {
	setValues?: any;	// set values and oldValues without merge
	oldValues?: any;	// merge properties into oldValues
	values?: any;		// merge properties into values
	errors?: any;		// merge properties into errors
	value?: any;		// merge [key] into values
	activeTab?: string;	// set active tab
	apply?: boolean;
	close?: boolean;
	success?: boolean;
	readOnly?: boolean;
	debug?: boolean;
	ready?: boolean;
	ioOngoing?: boolean;

	jsonSchema?: IJsonSchemaObject;		// root json schema
}

export interface IColumnElem {
	elem: ReactNode;
	options: {
		width?: number;
	};
}

interface IProfilingStat {
	min: number;
	max: number;
	total: number;
	avg: number;
	cnt: number;
}


interface IValueUpdate {
	value: any;
}

export interface IEmbedObjectOptions {
	useFlex?: boolean;
	isContainer?: boolean;
	noMargin?: boolean;
	noWrapper?: boolean;
}

export interface IUiSchemaElemArgs {
	key: string;
	fullkey: string;
	elem: IJsonSchemaObject;
	uiElem: IUiSchemaObject;
	readOnly: boolean;			// composite readOnly state, incl. modal readonly state
	elemReadOnly: boolean;		// readOnly state of only object.
	required: boolean;
	title: string;
	description: string;
	helpLink: string;

	layoutOptions?: IUiSchemaLayoutOptions;
	value: any;
	values: {
		[key: string]: any;
	};
	error: any;
	errors: any;
	enums: any[];
	enumLabels: {
		[key: string]: any;
	};
	update: (value: IValueUpdate) => void;
	dropFile?: IUiSchemaDropFile;

	type: string;

	self: Self;
	objects: IExprObjects;
	lib: ISchemaLib,
	embedObject: (obj: ReactNode, options?: IEmbedObjectOptions) => ReactNode;

	getSettings(scope: "map-provider"): { url: string, attribution: string, subDomains: string, maxNativeZoom: number };
	getSettings(scope: "default-map-center"): { zoom: number, lat: number, lng: number };

	stringToComponent: (text: string, args?: IUiSchemaElemArgs, key?: string) => ReactNode;
}

export interface IUiSchemaCardArgs {
	key: string;
	readOnly: boolean;
	hasError: boolean;
	title: string;

	layoutOptions?: IUiSchemaCardLayoutOptions;
	actionButtons?: IUiActionButton[];

	updateValues: (values: IUiSchemaSetValueResult) => void;
	updateLayout: () => void;

	self: Self;
	embedObject: (obj: ReactNode, options?: IEmbedObjectOptions) => ReactNode;
	getSettings: (scope: string) => any;
	stringToComponent: (text: string) => ReactNode;
}



export interface IListener {
	keys?: string[];
	tabs?: string[];
	handle: () => void;
}


export interface IElemNode {
	jsxElem: ReactNode;
	args: IUiSchemaElemArgs;
}



export interface ISchemaLocaleDictionary {
	"true": string;
	"false": string;
	click_to_unlock: string;
	cancel: string;
}

interface ISchemaCardList {
	title?: string;
	jsxElements: ReactNode[];
}

export interface IGetResourcesOptions {
	body?: any;
	headers?: {
		[header: string]: string;
	};
	chunkCallback?: (data: string, chunkIdx: number) => Promise<void>;
	responseObject: IResourceResponseObject;

	addAbortHandler: (handler: () => void) => void;
	removeAbortHandler: (handler: () => void) => void;

}


export interface ISchemaModalProps {
	jsonSchema?: IJsonSchemaObject;			// current schema
	jsonSchemaUrl?: string;

	object: any;
	updateState: (state: IUiSchemaUpdateState) => void;
	getResources: (method: string, url: string, options: IGetResourcesOptions) => Promise<{ ok: boolean, data: any, status?: number }>;
	showMessage: (type: "success" | "error" | "confirm", message: string) => Promise<boolean>;

	loadDataOnOpen: boolean;
	initialReadOnly: boolean;
	extensions?: IComponentHandlers;
	textMarkerExtensions?: ITextMarkerHandlers;
	libExtensions?: ISchemaLib;
	initialActiveTab: string;
	localeDictionary: ISchemaLocaleDictionary;
	lang?: string;			// language
	defaultLayoutOptions: IUiSchemaPanelLayoutOptions;
	debug?: boolean;
	log: (...args: any) => void;
	getSettings: (scope: string) => any;
	helpLinkCallback?: (link: string) => void;
}

export interface SchemaControllerRef {
	updateValues: (targetObj: IUiSchemaSetValueResult, keyPath: string, key: string) => void;
	getControlButtons: (readOnly: boolean, allowDebug: boolean, debug: boolean) => ReactNode;
	self: Self;
}


interface IHighlighCtrl {
	unlockDivRef: React.RefObject<HTMLDivElement>;
	overlayRef: React.RefObject<HTMLDivElement>;
	highlightValue: number;
	highlightTarget: number;
	highlightTimer: NodeJS.Timeout | undefined;
}

interface IReLayoutCtrl {
	layoutRef: React.RefObject<ISchemaLayoutComponentRef>;
	updateLayoutTimer: NodeJS.Timeout | undefined;
}



export interface Self {
	objects: IExprObjects;
	lib: ISchemaLib;

	instantValues: any;
	instantOldValues: any;
	instantErrors: any;

	schemaOptions: ISchemaOptions;


	control: IControl;
	log: (...args: any) => void;

	props: ISchemaModalProps;
	componentHandlers: IComponentHandlers;

	listeners: IListener[] | null;

	highlightUnlock: (val?: number) => void;
	updateLayout: (time?: number) => void;

	refresh: () => void;
	busyWithResources: number;

	loadedPanels: { [key: string]: boolean; };
	loadedCards: { [key: string]: boolean; };


	innerStates: IInnerStates,
	formInnerState: { [key: string]: any; }

	resourceStates: ResourceStates,
	abortHandlers: Array<{handler: () => void, id: string }>,

	removeAbortHandler: (handler: () => void) => void;
	abortAll: () => void;

	deferredUpdate: Array<() => void>;


	intervalTimers: NodeJS.Timeout[];

	highlightCtrl: IHighlighCtrl;
	reLayoutCtrl: IReLayoutCtrl;

	profiling: { [func: string]: IProfilingStat };

}


export interface ResourceStates {
	[id: string]: {
		initialLoad: boolean;
	}
}



export function trustExpr(trust: UiSchemaTrust) {
	return trust === true || (Array.isArray(trust) && trust.includes("trustExpr"));
}




export const SchemaController = forwardRef((props: ISchemaModalProps, ref: Ref<SchemaControllerRef>) => {

	const selfRef = React.useRef<Self>();
	const [_updateCnt, setUpdateCnt] = React.useState<number>(0);

	useEffect(() => {
		selfRef.current = initSelf(props);
		selfRef.current.refresh = () => setUpdateCnt(lst => lst + 1);


		loadSchema(selfRef.current);
		selfRef.current.refresh();

		return () => {
			// Cleanup
			const self = selfRef.current;
			for (const it of self.intervalTimers) {
				clearInterval(it);
			}
			self.abortAll();
			self.intervalTimers = [];
		};


	}, []);


	useEffect(() => {

		updateProps(selfRef.current, props);

	}, [props]);


	useImperativeHandle(ref, () => ({
		updateValues: (targetObj: IUiSchemaSetValueResult, keyPath: string, key: string) => {
			updateValues(selfRef.current, targetObj, keyPath, key);
		},
		getControlButtons: (readOnly: boolean, allowDebug: boolean, debug: boolean) => {
			return getControlButtons(selfRef.current, readOnly, allowDebug, debug);
		},
		self: selfRef.current,
	}));



	return render(selfRef.current);
});





function addSchema(args: IUiSchemaElemArgs) {

	const { value, self, fullkey } = args;
	const { props } = self;

	/**
	 * FIXME:
	 * We hardcode this to store in the dashboard for the moment. We'll change
	 * this later...
	 * @param state 
	 */
	const updateState = (state: IUiSchemaUpdateState) => {

		const keyPathArr = (args.fullkey || "").split("/");
		const key = keyPathArr.pop();
		const keyPath = keyPathArr.join("/");

		if (state.currentValues?.dashboard && state.currentValues?.dashboard !== state.lastValues?.dashboard) {
			updateValues(args.self, {
				values: {
					"dashboard/var": state.currentValues.dashboard,
				}
			}, keyPath, key);
		}
	}

	return <SchemaController 
		key={fullkey}
		jsonSchema={value}
		object={({})}
		initialReadOnly={false}
		initialActiveTab=""
		updateState={updateState}
		getResources={props.getResources}
		showMessage={props.showMessage}
		loadDataOnOpen={true}
		localeDictionary={props.localeDictionary}
		defaultLayoutOptions={props.defaultLayoutOptions}
		log={props.log}
		getSettings={props.getSettings}
	/>;

}

registerComponentHandler("schema", addSchema);






function updateProps(self: Self, props: ISchemaModalProps) {

	if (!self) { return; }

	const rootJsonSchema = self.objects?.rootJsonSchema;

	// before having a schema we just return
	if (rootJsonSchema) {

		// Run deferred updates
		for (const cb of self.deferredUpdate || []) {
			cb();
		}
		self.deferredUpdate = [];

		if (self.props.debug !== props.debug && props.debug) {
			self.log("objects", self.objects);
			self.log("values", self.instantValues);
			self.log("errors", self.instantErrors);
			self.log("schema", rootJsonSchema);
			self.log("cond-schema", self.objects.rootCondJsonSchema);
			self.log("profiling", self.profiling);
		}

	}

	const reloadSchema = self.props.jsonSchema !== props.jsonSchema || self.props.jsonSchemaUrl !== props.jsonSchemaUrl;
	self.props = props;

	if (reloadSchema) {
		loadSchema(self);
	}

	self.refresh();
}




async function loadSchema(self: Self) {

	let jsonSchema: IJsonSchemaObject = self.props.jsonSchema;

	if (!jsonSchema) {

		const req = await self.props.getResources("GET", self.props.jsonSchemaUrl, {
			responseObject: {} as any, 
			addAbortHandler: (handler) => self.abortHandlers.push({ handler, id: "__load_schema__" }),
			removeAbortHandler: self.removeAbortHandler
		});
		
		if (req.ok) {
		
			jsonSchema = req.data;
			self.objects.oldRootJsonSchema = jsonSchema;
			
			if (jsonSchema.$uiSchema?.libFunctions) {
				for (const funcName of Object.keys(jsonSchema.$uiSchema?.libFunctions)) {
					try {
						self.lib[funcName] = eval("(" + jsonSchema.$uiSchema?.libFunctions[funcName] + ")");
					} catch (e) {
						console.log("Error adding lib function " + funcName, e.message);
					}
				}
			}
		}
	}

	if (jsonSchema) {

		updateValues(self, {
			jsonSchema,
			ready: jsonSchema?.$uiSchema?.modal?.ready !== false,
			...(typeof jsonSchema?.$uiSchema?.modal?.readOnly === "boolean" ? { readOnly: jsonSchema.$uiSchema.modal.readOnly } : null)
		}, "", "");
		self.refresh()

	} else {

		console.log("No valid schema");

	}
}








/**
 * Init the self object. This just requires the props type. The only part of the self object that need to be
 * setup outside this function is the self.refresh() hook that need to be implemented using a state.
 * 
 * @param props 
 * @returns 
 */
function initSelf(props: ISchemaModalProps) {

	const abortHandlers: Array<{handler: () => void, id: string }> = [];
	const self: Self = {} as any;

	const log = (...args: any) => self.props.log(...args);
	const lib = {
		...props.libExtensions,
		...registeredExtensionLib,

		log,
		showMessage: (type: "success" | "error" | "confirm", msg: string) => self.props.showMessage(type, msg),

		abortResourceById: (id: string) => {
			for (const handle of abortHandlers) {
				if (handle.id === id) {	handle.handler(); }
			}
		},
	}

	const instantValues     = props.object || {};
	const instantOldValues  = instantValues;
	const rootJsonSchema    = props.jsonSchema;
	const oldRootJsonSchema = rootJsonSchema;
	const uiSchema          = rootJsonSchema?.$uiSchema;
	const values            = proxyClone(instantValues    || {}, rootJsonSchema);
	const oldValues         = proxyClone(instantOldValues || {}, rootJsonSchema);

	const schemaOptions     = { treatNullAsUndefined: true, useDefaults: true, additionalProperties: true, contOnError: false, skipOnNullType: false };
	const control = {
		ready: false,
		activeTab: props.initialActiveTab || "",
		readOnly: props.initialReadOnly || false,
		modalReadOnly: false,
		apply: false,
		ioOngoing: false,
	};

	const objects           = { values, oldValues, oldRootJsonSchema, rootJsonSchema, rootCondJsonSchema: rootJsonSchema,
								jsonSchema: rootJsonSchema, errors: {}, uiSchema, control } as IExprObjects;
	const instantErrors     = checkObject(null, objects, lib, schemaOptions);
	objects.errors          = proxyClone(instantErrors, rootJsonSchema);


	const selfInit: Self = {
		objects,
		props,
		instantErrors,
		instantOldValues,
		instantValues,
		control,
		schemaOptions,
		lib,
		log,

		componentHandlers: {
			...registeredExtensionHandlers,
			...Object.keys(registeredExtensionFormsComponents).reduce((o, k) => ({ ...o, [k]: registeredExtensionHandlers["string"]}), {}),
			...props.extensions,
		},

		formInnerState: {},
		innerStates: {},
		resourceStates: {},

		refresh: () => {},		// override me outside
		highlightUnlock: (target = 1) => highlightUnlock(self, target),
		updateLayout: (delay?: number) => updateLayout(self, delay),

		listeners: null,


		/* Resources */
		intervalTimers: [],

		busyWithResources: 0,
		abortHandlers,

		removeAbortHandler: (handler: () => void) => {
			const idx = abortHandlers.findIndex(h => h.handler === handler);
			if (idx >= 0) { abortHandlers.splice(idx, 1); }
		},
		abortAll: () => {
			for (const handle of abortHandlers) { handle.handler(); }
			abortHandlers.splice(0, abortHandlers.length);
		},

		highlightCtrl: {
			unlockDivRef: React.createRef<HTMLDivElement>(),
			overlayRef: React.createRef<HTMLDivElement>(),
			highlightValue: 0,
			highlightTarget: 0,
			highlightTimer: null,
		},
		reLayoutCtrl: {
			layoutRef: React.createRef<ISchemaLayoutComponentRef>(),
			updateLayoutTimer: null,
		},

		loadedCards: {},
		loadedPanels: {},
		deferredUpdate: [],
		profiling: {},
	};
	Object.assign(self, selfInit);


	return self;
}



/**
 * The cardList can be hierarchal to layout more complex view. However most of the time we simply need a simple
 * list of all the cards. This function flatten the list and return just the list of cards.
 * 
 * @param cardsMap 
 * @param cards 
 */
export function flatCardList(cardsMap: {}, cards: CardListElem[]) {
	for (const card of cards) {
		if (Array.isArray(card)) {
			flatCardList(cardsMap, card);
		} else if (typeof card === "string") {
			cardsMap[card] = 1;
		}
	}
}








/**
 * this is called after the updateValues() function has run and new values are set.
 * This function will perform all the functions that are triggered by any change of value.
 */
function valuesUpdated(self: Self, oldObjects: IExprObjects, newObjects: IExprObjects) {

	const oldControl = oldObjects.control;
	const newControl = newObjects.control;

	let resourceTriggered = false;

	if (oldObjects.values !== newObjects.values) {

		// check listeners
		for (const listener of self.listeners || []) {
			const cValues = newObjects.values;
			const pValues = oldObjects.values;

			for (const key of listener.keys || []) {
				if (cValues[key] !== pValues[key]) {
					self.log("Resource triggered " + key + " change " + cValues[key] + "!==" + pValues[key]);
					resourceTriggered = true;
					listener.handle();
					break;
				}
			}
		}
	}

	if (oldControl !== newControl) {

		if (newControl.activeTab !== oldControl.activeTab) {
			// Check triggers on tabs
			for (const listener of self.listeners) {
				if (listener.tabs && listener.tabs.includes(newControl.activeTab)) {
					self.log("Resource triggered on tab " + newControl.activeTab);

					resourceTriggered = true;
					listener.tabs = null;	// trigger only once
					listener.handle();
				}
			}
		}
	}


	if (newControl.apply) {

		const { rootJsonSchema } = self.objects;
		const uiSchema = rootJsonSchema.$uiSchema;

		if (!oldControl.apply) {
			for (const datres of uiSchema.dataResources || []) {
				// exec immediately for onApply handlers.
				if (datres.triggerOnApply) {

					resourceTriggered = true;

					self.log("Resource on apply triggered");
					handleResource(self, datres);
				}
			}
		}

		// Finally, we close the dialog when three is no more IO ongoing
		if (!self.busyWithResources && !resourceTriggered) {
			updateValues(self, { close: uiSchema.modal?.closeOnApply !== false, success: true, apply: false, ioOngoing: false }, "", "");
		}
	}

}





/**
 * renderSchema
 * Main render function to generate a composite react component created based on
 * the schema.
 *
 * @param card - name of card in UISchema to be rendered
 * @returns React component
 */

export function renderSchema(self: Self, card: string, rootCondJsonSchema: IJsonSchemaObject, layoutOptions: IUiSchemaPanelLayoutOptions) {

	const jsxElems: ReactNode[] = [];
	const cards: ISchemaCardList[] = [{ jsxElements: jsxElems }];
	const uiSchema: IUiSchema = rootCondJsonSchema?.$uiSchema || {};
	const { lang } = self.props;
	const lib         = self.lib;
	const control     = self.objects.control;
	const oldValues   = self.objects.oldValues;
	const valuesProxy = self.objects.values;
	const errorsProxy = self.objects.errors;
	const { oldRootJsonSchema, rootJsonSchema } = self.objects;
	
	let hasError = false;

	if (!rootCondJsonSchema) { return { hasError, cards: [] }; }

	const cardProps = uiSchema && uiSchema.cards && uiSchema.cards[card];
	const keys = cardProps?.properties;



	const parseArray = (args: IUiSchemaElemArgs,
						layoutOptions: IUiSchemaPanelLayoutOptions,
						pushElem: (elem: ReactNode, args: IUiSchemaElemArgs) => void) => {

		if (!args.elem.items) {
			self.log("Missing items in", args.elem);
			return;
		}

		const { fullkey, required, objects, elem } = args;
		const { values, jsonSchema } = objects;
		const uiElem      = elem?.$uiSchemaObject || {};
		let   itemElem    = elem.items;
		
		if (itemElem.$ref) { itemElem = getRef(itemElem, rootCondJsonSchema, true); }

		const itemUiElem  = itemElem.$uiSchemaObject || {};

		const arrayElems: ReactNode[] = [];
		const arrayRows: ArrayRow[] = [];		// New - first for tables only


		const arrayValuesProxy = valuesProxy[fullkey + "?"];
		const arrayErrorsProxy = errorsProxy[fullkey + "?"];
		const arrayValues      = arrayValuesProxy["."] || [];
		const arrayRenderMode  = uiElem.arrayElementRenderMode || layoutOptions.arrayElementRenderMode;

		const exprTrust        = trustExpr(uiElem.trust);
		const editArray = typeof uiElem.editArray === "string" ?
							evalExpr(exprTrust, uiElem.editArray, lib, objects, 
								{ fullkey: args.fullkey, value: args.value, readOnly: args.readOnly, error: args.error, schema: elem }, false) :
								uiElem.editArray;

		const boxType = itemUiElem.type === "table" ? "table" : (itemUiElem.type === "card" ? "card" : "accordion");
		const arrLayoutOptions = {...layoutOptions};
		if (boxType === "table") {
			arrLayoutOptions.titleLayout       = "none";
			arrLayoutOptions.descriptionLayout = "popup";
		}

		// This function will update a __acc_ prefixed control variable to show the
		// active entry in the array accordion
		const setActive = (activeArrayKey: string) => {
			if (boxType === "accordion") {
				const acckey = "__acc_" + fullkey.replace(/[/]/g, ".");
				updateValues(self, { values: { [acckey]: activeArrayKey }}, "", "" );
			}
		}

		// 

		let arrControls: string[] = [];
		if (editArray && !args.readOnly) {
			if (Array.isArray(uiElem.editArrayControls)) {
				arrControls = uiElem.editArrayControls;
			} else if (typeof uiElem.editArrayControls === "string") {
				arrControls = evalExpr(exprTrust, uiElem.editArrayControls, lib, objects, { fullkey: "", value: args.value, error: args.error, readOnly: args.readOnly, schema: elem }, undefined);
			}
		}
		const canAppend = Array.isArray(arrControls) && arrControls.includes("append")  && (elem.maxItems == null || arrayValues.length < elem.maxItems);


		const emptyArray = uiElem.treatEmptyAs ? evalExpr(exprTrust, uiElem.treatEmptyAs, lib, objects,
						{ fullkey, value: args.value, error: args.error, readOnly: args.readOnly, schema: elem }, []) : [];


		for (let idx = 0; idx < arrayValues.length; idx++) {

			let  arElem   = (elem.itemsArray && elem.itemsArray[idx]) || itemElem;
			if (arElem.$ref) { arElem = getRef(arElem, rootCondJsonSchema, true); }


			const arUiElem = itemElem.$uiSchemaObject || {};
			const arFullkey = fullkey + "/" + idx;
			const rIdx = idx;

			const arrayArgs = getArgs(jsonSchema, arElem, arUiElem, layoutOptions, fullkey, idx, arrayValuesProxy, arrayErrorsProxy, required);
			if (!arrayArgs) { continue; }


			let arrControls: string[] = [];
			if (editArray && !args.readOnly) {
				if (Array.isArray(uiElem.editArrayControls)) {
					arrControls = uiElem.editArrayControls;
				} else if (typeof uiElem.editArrayControls === "string") {
					arrControls = evalExpr(exprTrust, uiElem.editArrayControls, lib, objects, { fullkey: arrayArgs.fullkey, value: arrayArgs.value, error: arrayArgs.error, readOnly: arrayArgs.readOnly, schema: arElem }, undefined);
				}
			}
			const canInsert = Array.isArray(arrControls) && arrControls.includes("insert") && (elem.maxItems == null || arrayValues.length < elem.maxItems)
			const canDelete = Array.isArray(arrControls) && arrControls.includes("delete") && (elem.minItems == null || arrayValues.length > elem.minItems)


			const deleteHandle = () => {
				const arr = [...args.value];
				arr.splice(rIdx, 1);
				args.update({ value: arr.length === 0 ? emptyArray : arr });
			};
			const insertHandle = () => {
				const arr = [...args.value];
				const newElem = uiElem.editArrayAddElement
						? evalExpr(exprTrust, uiElem.editArrayAddElement, lib, objects, { fullkey, value: args.value, error: args.error, readOnly: args.readOnly, schema: elem }, undefined)
						: (itemElem.type === "object" ? {} : itemElem.type === "array" ? [] : undefined);

				arr.splice(rIdx, 0, newElem);
				args.update({ value: arr });
				setActive(String(rIdx));
			};

			// determine if this element content should be rendered.
			let renderElemContent = true;
			if (boxType === "accordion") {
				renderElemContent = arrayRenderMode === "render-always" || values["/__acc_" + fullkey.replace(/[/]/g, ".")] === idx;
			}

			if (arElem.type === "object") {

				const arrayJsxElemObjects: ReactNode[] = [];
				let arrayColElems: IElemNode[] = [];

				const arrayPushElem = (jsxElem: ReactNode, args: IUiSchemaElemArgs) => 
													arrayColElems.push({ jsxElem, args });

				if (renderElemContent) {

					const orderedKeys = getOrderedKeys(Object.keys(arElem.properties || {}), arElem.properties);
					parseObject(orderedKeys,							// keys of object
								arFullkey,								// absolute path of the object
								arElem,									// schema
								arrLayoutOptions,
								arrayPushElem);
				}


				if (boxType === "card") {

					// For a card layout we need to render to a layout
					schemaElemColLayout(self, cardProps, arrayColElems, arrayJsxElemObjects as JSX.Element[], layoutOptions.numColumns || 0);

					// TODO: add controls (add, delete, etc)
					cards.push({ jsxElements: arrayJsxElemObjects });

				} else if (boxType === "accordion") {

					// For a according layout we need to render to a layout
					schemaElemColLayout(self, cardProps, arrayColElems, arrayJsxElemObjects as JSX.Element[], layoutOptions.numColumns || 0);

					arrayElems.push(embedArrayElementObject(arrayArgs, arrayJsxElemObjects, boxType,
													canDelete && deleteHandle, canInsert && insertHandle));
				} else if (boxType === "table") {

					arrayRows.push({ 
						elemNodes:  arrayColElems,
						controls: {
							add: canInsert ? insertHandle : null,
							rem: canDelete ? deleteHandle : null,
						}
					})

				}

			} else if (arElem.type === "array") {

				let jsxArrElem: ReactNode = null;
				parseArray(arrayArgs, arrLayoutOptions, (jsxElem: ReactNode, args: IUiSchemaElemArgs) => { jsxArrElem = jsxElem});

				if (jsxArrElem) {
					if (boxType === "card") {
						// TODO: add controls (add, delete, etc)
						cards.push({ jsxElements: [jsxArrElem] });
					} else {
						arrayElems.push(embedArrayElementObject(arrayArgs, jsxArrElem, boxType,
														canDelete && deleteHandle, canInsert && insertHandle));
					}
				}

			} else {

				if (arrayArgs && arrayArgs.type && self.componentHandlers[arrayArgs.type]) {
					const obj = self.componentHandlers[arrayArgs.type](arrayArgs);

					if (obj && !(obj as JSX.Element).key) { console.log("Missing key in array", arrayArgs, args); }

					obj && arrayElems.push(embedArrayElement(idx + "", obj, canDelete && deleteHandle,
																	canInsert && insertHandle));
				}
			}

		}

		// Prepare add new array element handle
		const addHandle = () => {
			const newElem = uiElem.editArrayAddElement
						? evalExpr(exprTrust, uiElem.editArrayAddElement, lib, objects, { fullkey, value: args.value, error: args.error, readOnly: args.readOnly, schema: elem }, undefined)
						: (itemElem.type === "object" ? {} : itemElem.type === "array" ? [] : undefined);
			args.update({ value: [...(args.value || []), newElem] });
			setActive((args.value || []).length);
		};


		if (boxType === "table") {
			const jsxTable = renderArrayTable(
								arrayRows, canAppend && addHandle,
								args, itemElem, self.objects.control, self.objects, self.props, self.lib);

			const arrayObj = embedObject(args, jsxTable, { isContainer: true });
			arrayObj && pushElem(arrayObj, args);

		} else if (boxType !== "card") {

			// Wrap array in container and then in object embed
			const arrayContainer = embedArrayContainer(args, itemElem, arrayElems, canAppend && addHandle, boxType);
			const arrayObj = embedObject(args, arrayContainer, { isContainer: true });
			arrayObj && pushElem(arrayObj, args);
		}


	}




	const parseObject = (keys: string[], rootPath: string,
							baseJsonSchema: IJsonSchemaObject,
							layoutOptions: IUiSchemaPanelLayoutOptions,
							pushElem: (elem: ReactNode, args: IUiSchemaElemArgs) => void) => {

		for (const keyGrp of keys) {

			const pathArr = keyGrp.replace(/[.]/g, "/").split("/");			// for the MODEL we convert . to /
			const key     = pathArr.pop() as string;
			const path    = pathArr.join("/");
			let   keypath = rootPath + (rootPath && path ? "/" : "") + path;
			let   fullkey = (keypath ? keypath + "/" : "") + key;
			const values  = valuesProxy[keypath + "?"];
			const errors  = errorsProxy[keypath + "?"];

			// now resolve the schema object
			const sPathArr   = keyGrp.split("/");			// For the SCHEMA we keep the . separated elements in the key
			const sKey       = sPathArr.pop() as string;
			let   jsonSchema = baseJsonSchema;

			for (const pKey of sPathArr) {
				jsonSchema = (jsonSchema?.properties || {})[pKey] || {};
			}
			const properties = jsonSchema.properties || {};
			let   elem       = properties[sKey];

			if (elem == null) { continue; }
			if (elem.$ref) { elem = getRef(elem, rootCondJsonSchema, true); }

			const uiElem   = (elem || {}).$uiSchemaObject || {};
			const required = !!(jsonSchema.required && jsonSchema.required.includes(key));
			const args     = getArgs(jsonSchema, elem, uiElem, layoutOptions, keypath, key, values, errors, required);
			if (!args) { continue; }

			// Process the object. If it is an ARRAY we will loop over all the elements. Further if it is an array of Objects
			// we will process the object properties directly.

			if (args.type === "array") {

				parseArray(args, layoutOptions, pushElem);

			} else if (args.type === "object") {

				const objectJsxElemObjects: ReactNode[] = [];
				const objectColElems: IElemNode[] = [];
				const objectLayoutOptions = { ...layoutOptions, ...args.uiElem.layoutOptions };
				const objectPushElem = (jsxElem: ReactNode, args: IUiSchemaElemArgs) => 
												objectColElems.push({ jsxElem, args });

				const orderedKeys = getOrderedKeys(Object.keys(elem.properties || {}), elem.properties);
				parseObject(orderedKeys,	// keys of object
					fullkey,				// absolute path of the object
					elem,					// schema
					{ ...objectLayoutOptions, ...args.uiElem.nestedLayoutOptions },
					objectPushElem);
				
					schemaElemColLayout(self, cardProps, objectColElems, objectJsxElemObjects as JSX.Element[], objectLayoutOptions.numColumns || 0);
					const embeddedObj = embedObject(args, <>{objectJsxElemObjects}</>, { isContainer: true });

					pushElem(embeddedObj, args);

			} else {

				// The object is NOT an array, so we just invoke directly the component handler.

				if (args && args.type && self.componentHandlers[args.type]) {
					const obj = self.componentHandlers[args.type](args);

					if (obj && !(obj as JSX.Element).key) { console.log("Missing key in object", args); }

					obj && pushElem(obj, args);
				}
			}
		}
	}



	// getArgs parse an element and generate the IUiSchemaElemArgs structure that is passed to all component
	// functions

	const getArgs = (jsonSchema: IJsonSchemaObject, elem: IJsonSchemaObject, uiElem: IUiSchemaObject, layoutOptions: IUiSchemaPanelLayoutOptions,
						keypath: string, key: string | number,
						values: any, errors: any, required: boolean) => {

		const fullkey = (keypath ? keypath + "/" : "") + key;
		const error   = errors[key];
		const objects = { values, errors, oldRootJsonSchema, rootJsonSchema, rootCondJsonSchema, jsonSchema, uiSchema, oldValues, control };

		let value = values[key];
		if (value === undefined && !self.innerStates[fullkey]?.modified) { value = elem.default };

		// Resolve readOnly states
		const exprTrust  = trustExpr(uiElem.trust);
		const evReadOnly = typeof uiElem.readOnly === "boolean" ? typeof uiElem.readOnly
							: typeof uiElem.readOnly === "string"
								? evalExpr(exprTrust, uiElem.readOnly, lib, objects, { fullkey, value, error, readOnly: null, schema: elem }, false) : null;
		const elemReadOnly = typeof evReadOnly === "boolean" ? evReadOnly :
							(typeof elem.readOnly === "boolean" ? elem.readOnly : (control.modalReadOnly || false));
		const readOnly = typeof evReadOnly === "boolean" ? evReadOnly : (elemReadOnly || control.readOnly);

		// Check if this element should be hidden
		if (uiElem.hidden === true || (typeof uiElem.hidden === "string" && 
				evalExpr(exprTrust, uiElem.hidden, lib, objects, { fullkey, value, error, readOnly, schema: elem }, false))) {
			return null;
		}

		if (uiElem.getValue) {
			try {
				value = evalExpr(exprTrust, uiElem.getValue, lib, objects, { fullkey, value, error, readOnly, schema: elem }, Error);
			} catch (e: any) {
				self.log("error in custom getValue function for " + key + ":" + e.message);
			}
		}

		const enumElem = uiElem.getEnum
							? evalExpr(exprTrust, uiElem.getEnum, lib, objects, { fullkey, value: uiElem.enum || elem.enum, readOnly, schema: elem }, [])
							: (uiElem.enum || elem.enum);
		const labels: any = {};
		if (enumElem) {
			const enumLabels = uiElem.getEnumLabels
								? evalExpr(exprTrust, uiElem.getEnumLabels, lib, objects, { fullkey, value: uiElem.enumLabels, readOnly, schema: elem }, [])
								: uiElem.enumLabels;

			if (enumLabels) {
				for (const uie of enumLabels) {
					labels[uie.value + ""] = uie.label;
				}
			}
		}


		// Process titles and descriptions
		const titleLayout = uiElem?.titleLayout       || layoutOptions.titleLayout;
		const descLayout  = uiElem?.descriptionLayout || layoutOptions.descriptionLayout;
		const description = evalString(uiElem.trust, (lang && (elem as any)["description[" + lang + "]"]) || elem.description || "",
										lib, objects, { fullkey, value, error, readOnly, schema: elem }) + "";
		const title       = evalString(uiElem.trust, (lang && (elem as any)["title[" + lang + "]"]) || elem.title || key,
										lib, objects, { fullkey, value, error, readOnly, schema: elem }) + "";
		const helpLink = uiElem.helpLink ? evalString(uiElem.trust, uiElem.helpLink, lib, objects, 
							{ fullkey, value, error, readOnly, schema: elem }) + "" : null;


		const type = uiElem?.type || (elem.type && enumElem ? "select" : (Array.isArray(elem.type) ? elem.type[0] : elem.type)) || "";

		if (uiElem?.placeholder) {
			uiElem = {
				...uiElem,
				placeholder: evalString(uiElem.trust, (lang && (uiElem as any)["placeholder[" + lang + "]"]) || uiElem.placeholder,
										lib, objects, { fullkey, value, error, readOnly, schema: elem }) + ""
			};
		}

		const elementArgs: IUiSchemaElemArgs = {
			key: key as string, 			// TODO:
			fullkey,
			elem,
			layoutOptions,
			uiElem,
			// The value parsed is normally the value, but can be also the title or description text.
			value: titleLayout === "value" ? title : descLayout === "value" ? description : value,
			values,
			title,
			description,
			helpLink,
			readOnly,
			elemReadOnly,
			required,
			error,
			errors,
			dropFile: uiElem.dropFile,
			update: (update: IValueUpdate) => {
				const ts0 = Date.now();
				let targetObj: IUiSchemaSetValueResult = { value: update.value };
				if (uiElem.setValue) {
					try {
						targetObj = evalExpr(exprTrust, uiElem.setValue, lib, objects, { fullkey, value: update.value, error, readOnly, schema: elem }, Error);
						self.log("setValue", targetObj, update.value);
					} catch (e: any) {
						self.log("error in custom setValue function for " + key + ":" + e.message);
					}
				}

				if (targetObj) { updateValues(self, targetObj, keypath, key + ""); }
				logTime(self, "update", Date.now() - ts0);
			},
			enumLabels: labels,
			enums: enumElem as any,
			type,

			objects,
			self,
			lib,
			embedObject: (obj: any, flex) => embedObject(elementArgs, obj, flex),		// FIXME: 
			getSettings: self.props.getSettings,

			stringToComponent: (text: string, _args?: IUiSchemaElemArgs, _key?: string) => {
				const { fullkey, value, error, readOnly, elem } = _args || elementArgs;
				const txt = evalString(uiElem.trust, text, lib, objects, { fullkey, value, error, readOnly, schema: elem }) + "";
				const jsx = formatTextArgs(self, uiElem.trust, txt, _args || elementArgs, _key || fullkey);
				return jsx;
			}
		};

		// If there is a value to be auto set, we schedule it to be updated after the render is completed.
		if (uiElem.autoSet && enumElem?.length > 0 && !enumElem.includes(value)) {
			self.deferredUpdate.push(() => elementArgs.update({ value: enumElem[0] }));
		}

		if (!type || (!self.componentHandlers[type] && type !== "array" && type !== "object")) {
			self.log("Unknown type " + type + " for " + fullkey);
		}
		if (error) { hasError = true; }


		return elementArgs;
	};



	// Implement the column layout function that is passed to the parseObject function and is used to
	// layout the added fields in columns, depending on layoutOptions.
	const elemNodes: IElemNode[] = [];
	const pushElem = (jsxElem: ReactNode, args: IUiSchemaElemArgs) => elemNodes.push({ jsxElem, args });

	// Start scanning the object via the supplied key list.
	//
	if (rootCondJsonSchema && rootCondJsonSchema.type === "object" && rootCondJsonSchema.properties && keys) {
		parseObject(keys, "", rootCondJsonSchema, layoutOptions, pushElem);
	}

	// Layout the interface
		schemaElemColLayout(self, cardProps, elemNodes, jsxElems as JSX.Element[], layoutOptions.numColumns || 0);

	return { hasError, cards };
}



/**
 * getOrderedKeys take the list of keys and lookup the objects to retrieve ordering information
 * in the schema data, such as the uischema idx field.
 * 
 * @param keys 
 * @param obj 
 * @returns 
 */
function getOrderedKeys(keys: string[], obj: { [key: string]: IJsonSchemaObject }) {

	return keys.sort((a, b) => {
		const ia = obj[a]?.$uiSchemaObject?.order ?? obj[a]?.$uiSchemaObject?.idx;
		const ib = obj[b]?.$uiSchemaObject?.order ?? obj[b]?.$uiSchemaObject?.idx;
		return ia == null || ib == null ? 0 : ia - ib;
	});
}



/**
 * Return the first tab key, i.e. the first kex in the panel object.
 * 
 * @param self 
 * @returns 
 */
export function getFirstTabKey(self: Self) {

	const uiSchema: IUiSchema = self.objects.rootJsonSchema?.$uiSchema || {};
	if (uiSchema && uiSchema.panels) {
		for (const tabKey of Object.keys(uiSchema.panels)) {
			const tab = uiSchema.panels[tabKey];
			if (tab.title) { return tabKey; }
		}
	}
	return undefined;
}




export function getTabItems(self: Self, activeTabKey: string, objects: IExprObjects) {
	const errors = self.instantErrors;
	const jsxElems: JSX.Element[] = [];
	const rootCondJsonSchema = objects.rootCondJsonSchema;
	const uiSchema: IUiSchema = rootCondJsonSchema?.$uiSchema || {};
	const readOnly = !!(self.objects.control.modalReadOnly || self.objects.control.readOnly);
	const lib = self.lib;


	if (!uiSchema || !uiSchema.panels) { return []; }

	for (const tabKey of Object.keys(uiSchema.panels)) {
		const tab = uiSchema.panels[tabKey];
		const hidden = tab.hidden && evalExpr(trustExpr(tab.trust), tab.hidden, lib, objects, { readOnly }, false);
		if (hidden) { continue; }

		if (tab.title) {

			let hasElements = false;
			let hasError    = false;

			const panelCardsMap = {};
			flatCardList(panelCardsMap, tab.cards);


			for (const cardKey of Object.keys(panelCardsMap)) {
				const card = uiSchema.cards && uiSchema.cards[cardKey];
				if (card?.hidden && evalExpr(trustExpr(card.trust), card.hidden, lib, objects, { readOnly }, false)) { continue; }

				for (const elemKey of card?.properties || []) {

					if (!hasElements) {
						let schema: IJsonSchemaObject | undefined = rootCondJsonSchema;
						let elemPresent = true;
						for (const key of elemKey.split(/[/]/)) {
							schema = schema?.properties && schema.properties[key];
							if (!schema) { elemPresent = false; break; }
						}

						if (schema?.$uiSchemaObject?.hidden != null) {
							const hidden = schema.$uiSchemaObject.hidden;
							if (hidden === true || (typeof hidden === "string" && 
										evalExpr(trustExpr(schema.$uiSchemaObject.trust), hidden, lib, objects, { fullkey: elemKey, readOnly, schema }, false))) {
								elemPresent = false;
							}
						}

						hasElements ||= elemPresent;
					}

					if (errors && !hasError) {
						let elemHasError = true;
						let obj          = errors;
						for (const key of elemKey.split(/[./]/)) {
							obj = obj[key];
							if (!obj) { elemHasError = false; break; }
						}
						hasError ||= elemHasError;
					}
				}
			}
			if (!hasElements && hidden !== false) { continue; }

			const lang = self.props.lang;
			const title: string = (lang && (tab as any)["title[" + lang + "]"]) || tab.title;
			const titleText = title && evalString(tab.trust, title, lib, objects, { readOnly }) + "";
			const titleObj = titleText && formatTextArgs(self, tab.trust, titleText, null, tabKey);
			jsxElems.push(addTabElem(tabKey, activeTabKey === tabKey, hasError, titleObj,
										() => updateValues(self, { activeTab: tabKey }, "", "")));
		}
	}

	return jsxElems;
}







/**
 * 
 * @param objects 
 * @param lib 
 * @param schemaOptions 
 * @returns 
 */
function checkObject(self: Self | null, objects: IExprObjects, lib: ISchemaLib, schemaOptions: ISchemaOptions) {

	if (self?.busyWithResources) {
		return {};
	}

//	const ts0 = Date.now();

	const schema = objects.rootCondJsonSchema || {};
	const errObj = evalSchemaElem(schema, schema, objects.values, self?.innerStates || {},
									{ ...schemaOptions, skipOnNullType: true, contOnError: true }, lib, objects) || {};

//	const ts1 = Date.now();
//	this.logTime(self, "checkobject", ts1-ts0);
	return errObj;
}





/**
 * 
 * @param self 
 * @param targetObj 
 * @param keyPath 
 * @param key 
 * @returns 
 */

export function updateValues(self: Self, targetObj: IUiSchemaSetValueResult, keyPath: string, key: string) {

	if (targetObj instanceof Promise) {
		targetObj.then(res => updateValues(self, res, keyPath, key));
		return;
	}
	if (!targetObj || Object.keys(targetObj).length === 0) {
		self.log("Update with empty object")
		return;
	}


	function objectMerge(dstObj: any, srcObj: any) {

		for (const k of Object.keys(srcObj)) {
			const path = (k.startsWith("/") ? k.substring(1) : (keyPath ? keyPath + "/" : "") + k).split("/");

			// Handle the .. and . operators
			for (let i = 0; i < path.length; i++) {
				if (path[i] === "..") {
					if (i > 0) { path.splice(i - 1, 2);
					} else { path.splice(i, 1); }
					i--;
				} else if (path[i] === ".") {
					path.splice(i, 1);
					i--;
				}
			}

			const key = path.pop() as string;
			let cv = dstObj;
			for (const pe of path) {
				const cve = cv && cv[pe];
				cv = cv[pe] = typeof cve === "object" ? (Array.isArray(cve) ? [...cve] : {...cve}) : {};
			}
			cv[key] = srcObj[k];
		}
		return dstObj;
	}

	const currentValues = self.instantValues;
	const cpValues = {...currentValues};
	const setValues = targetObj.setValues ? objectMerge({}, targetObj.setValues) : null;
	const { schemaOptions } = self;
	const lib = self.lib;

	const update: IUiSchemaUpdateState = Object.assign({ lastValues: currentValues },
		targetObj.hasOwnProperty("value") && { currentValues: objectMerge(cpValues, { [key]: targetObj.value }) },
		targetObj.values              && { currentValues: objectMerge(cpValues, targetObj.values) },
		targetObj.setValues           && { currentValues: setValues, oldValues: setValues },
		targetObj.oldValues           && { oldValues:     objectMerge({...self.instantOldValues },  targetObj.oldValues) },
		targetObj.errors              && { objectErrors:  targetObj.errors },
		targetObj.close != null       && { close:     targetObj.close },
		targetObj.success != null     && { success:   targetObj.success },
		targetObj.debug != null       && { debug:     targetObj.debug },

		// For attempts to update the Schema, this is only allowed when the allowSchemaUpdate setting is true
		(targetObj.jsonSchema && (self.objects.rootJsonSchema?.$uiSchema?.settings?.allowSchemaUpdate || !self.objects.rootJsonSchema)) && { jsonSchema: targetObj.jsonSchema },
	);

	self.log("Updating values", keyPath, key, targetObj, update);

	let oldObjects = self.objects

	if (update.jsonSchema || update.currentValues || update.oldValues || update.objectErrors) {

		self.instantValues    = update.currentValues || self.instantValues;
		self.instantOldValues = update.oldValues     || self.instantOldValues;

		const rootJsonSchema  = update.jsonSchema || self.objects.rootJsonSchema;
		const values    = update.currentValues || update.jsonSchema ? proxyClone(self.instantValues,    rootJsonSchema) : self.objects.values;
		const oldValues = update.oldValues     || update.jsonSchema ? proxyClone(self.instantOldValues, rootJsonSchema) : self.objects.oldValues;
		const objects   = { ...self.objects, rootJsonSchema, values, oldValues };

		objects.rootCondJsonSchema = updateConditionalSchema(rootJsonSchema || {}, objects.values, schemaOptions, lib, objects);
		objects.jsonSchema         = objects.rootCondJsonSchema;
		self.instantErrors         = { ...checkObject(self, objects, lib, schemaOptions), ...update.objectErrors };
		objects.errors             = proxyClone(self.instantErrors, rootJsonSchema)
		self.objects               = objects;

		if (update.jsonSchema) {
			self.listeners = handleNewSchemaResources(self, rootJsonSchema);
		}

		self.log("UPDATED OBJECTS", self.objects);

	}

	let oldControl = self.control;
	let control = self.control;

	if (targetObj.ready != null)     { control = {...control, ready: targetObj.ready };       update.update = true; }
	if (targetObj.activeTab != null) { control = {...control, activeTab: targetObj.activeTab }; }
	if (targetObj.readOnly != null)  { control = {...control, readOnly: targetObj.readOnly }; update.update = true; }
	if (targetObj.apply != null)     { control = {...control, apply: targetObj.apply };       update.update = true; }
	if (targetObj.ioOngoing != null) { control = {...control, ioOngoing: targetObj.ioOngoing };       update.update = true; }
	if (Object.keys(self.objects.errors).length !== Object.keys(oldObjects.errors).length) {  update.update = true; }

	self.control = control;


	// Handle the general readonly of the whole modal
	const uiSchema = self.objects.rootJsonSchema?.$uiSchema;
	let modalReadOnly = false;
	if (uiSchema?.modal?.readOnly) {
		if (typeof uiSchema.modal?.readOnly === "boolean") {
			modalReadOnly = uiSchema.modal?.readOnly;
		} else if (typeof uiSchema.modal?.readOnly === "string") {
			const readOnly = !!self.control.readOnly;
			modalReadOnly = evalExpr(trustExpr(uiSchema.modal.trust), uiSchema.modal.readOnly, lib, self.objects, { schema: self.objects.rootJsonSchema, readOnly }, false);
		}
	}
	if (self.control.modalReadOnly !== modalReadOnly) {
		update.update = true;
		self.control = { ...self.control, modalReadOnly };
	}
	
	
	if (oldControl != self.control) {
		self.objects = { ...self.objects, control: self.control };
	}
	
	if (update.close) {
		// When this is about to close, we evaluate the schema based 
		const oldValues: any = getObjectValues(self.objects.rootCondJsonSchema, self.instantOldValues, 
							{ treatNullAsUndefined: true, useDefaults: false, additionalProperties: true,
							  contOnError: false, skipOnNullType: false }, lib, self.objects);
		const newDiffValues: any = getObjectValues(self.objects.rootCondJsonSchema, self.instantValues, 
							{ treatNullAsUndefined: true, useDefaults: false, additionalProperties: true,
							  contOnError: false, skipOnNullType: false }, lib, self.objects);
		const newValues: any = getObjectValues(self.objects.rootCondJsonSchema, self.instantValues,
							{ treatNullAsUndefined: true, useDefaults: true, additionalProperties: true,
							  contOnError: false, skipOnNullType: false }, lib, self.objects);
		const diffValues: any = {};
		for (const key of Object.keys(newDiffValues)) {
			if (newDiffValues[key] !== oldValues[key]) {
				diffValues[key] = newDiffValues[key];
			}
		}	
		self.objects = { ...self.objects, oldValues, newValues, diffValues };
	}

	valuesUpdated(self, oldObjects, self.objects);
	self.props.updateState(update);

	// Finally trigger a component refresh (state update)
	self.refresh();

	self.log("objects", self.objects);
}



/**
 * take a string value and a filename and initiate a download of the data as a download file
 * 
 * @param value 
 * @param filename 
 * @param mime 
 */

const downloadAsFile = (value: string, filename: string, mime?: string) => {

    const file = new Blob([value], { type: mime || 'text/plain' });
    const downloadLink = document.createElement('a');

    downloadLink.download = filename;
    downloadLink.href = window.URL.createObjectURL(file);
    downloadLink.style.display = 'none';
    document.body.appendChild(downloadLink);
 
    downloadLink.click();

    document.body.removeChild(downloadLink);
    window.URL.revokeObjectURL(downloadLink.href);

	return true;
}

registerExtensionLibFunction("downloadAsFile", downloadAsFile);



const copyTextToClipboard = (text: string) => { 
	try {
		navigator.clipboard.writeText(text);
		return true; 
	} catch (e) { 
		return false;
	}
} 

registerExtensionLibFunction("copyTextToClipboard", copyTextToClipboard);




/**
 * 
 * @param self 
 * @param func 
 * @param time 
 */
export function logTime(self: Self, func: string, time: number) {
	const profile = self.profiling[func] = self.profiling[func] || {} as IProfilingStat;
	profile.cnt = (profile.cnt || 0) + 1;
	profile.min = profile.min == null || time < profile.min ? time : profile.min;
	profile.max = profile.max == null || time > profile.max ? time : profile.max;
	profile.total = (profile.total || 0) + time;
	profile.avg   = profile.total / profile.cnt;
}


export default SchemaController;
